<template>
  <div class="thank-you container">
    <div class="text-center">
      <h2 class="title">{{ blok.title }}</h2>
      <p class="description">{{ blok.description }}</p>
    </div>
    <nuxt-img
      quality="90"
      format="webp"
      placeholder
      :src="blok.image.filename"
      alt="thank you image"
      class="img"
    />
  </div>
</template>
<script>
import { useRoute, useContext, useAsync } from '@nuxtjs/composition-api'
import { useUserOrder } from "~/modules/customer/composables/useUserOrder";

export default {
  name: "ThankYou",
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup() {
    let route = useRoute();
    let order_num = route.value.query.order_num;
    const { app } = useContext();
    const { orders, search } = useUserOrder();


    useAsync(async () => {
      await search({
        pageSize: "999"
      })
      .then(res => {
        if (!res) return
        if (res.items && res.items.length > 0 && !res.items[0].number) return
        const order = res.items.find(o => o.number === order_num)

        if (!order) return
      
        app.$gtm.push({ ecommerce: null })
        app.$gtm.push({
          event: "purchase",
          ecommerce: {
            transaction_id: order.number,
            value: order.grand_total,
            shipping: order.total.total_shipping.value,
            tax: order.total.total_tax.value,
            currency: order.total.total_tax.currency,
            coupon: order.total.discounts,
            items: order.items.map((p, i) => {
              return {
                item_name: p.product_name,
                item_id: p.product_sku,
                price: p.product_sale_price.value,
                index: i + 1,
                quantity: p.quantity_ordered
              }
            })
          }
        })
      })
    });

    return {
      order_num
    }
  },
};
</script>

<style lang="scss" scoped>
.thank-you {
  @apply my-20;
}
.title {
  font: normal normal bold 55px/55px Montserrat;
  @media only screen and (max-width: 1024px) {
    font: normal normal bold 45px/55px Montserrat;
  }
  @media only screen and (max-width: 768px) {
    font: normal normal bold 35px/55px Montserrat;
  }
}
.description {
  font: normal normal bold 28px/35px "Franklin Gothic Book", sans-serif;
  max-width: 700px;
  margin: auto;
  @apply my-10;
  @media only screen and (max-width: 1024px) {
    font: normal normal bold 24px/35px "Franklin Gothic Book", sans-serif;
  }
  @media only screen and (max-width: 768px) {
    font: normal normal bold 20px/35px "Franklin Gothic Book", sans-serif;
  }
}
.img {
  max-width: 550px;
  width: 100%;
  height: auto;
  margin: auto;
  object-fit: cover;
  border-radius: 0.75rem;
  @media only screen and (max-width: 1024px) {
    max-width: 400px;
  }
}
</style>
