<template>
  <div class="container relative pt-10">
    <div class="flex questions-global-wrapper">
      <div class="flex-1 questions-global">
        <div
          v-for="(item, index) in blok.questions"
          :key="index"
          class="question-group w-full py-6"
        >
          <label
            class="font-bold hover:cursor-pointer"
            @click="activate(index)"
            :class="{ active: activeIndex === index }"
            >{{ item.title }}</label
          >
        </div>
      </div>
      <div class="flex-1 scroll font-mont">
        <div
          v-for="(item, index) in blok.questions"
          :key="index"
          class="flex questions"
        >
          <div v-if="activeIndex === index" class="questions-wrapper">
            <ul
              v-for="(question, index) in item.blocks"
              :key="index"
              @click="activeQuestion(index, question)"
              class="list-disc"
              :class="{ activeQuestion: activeIndexQuestion === index }"
            >
              <li class="question hover:cursor-pointer py-6">
                {{ question.text }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="answer p-20 px-30 w-full m-auto" v-if="activeAnswer">
      <h3 class="answer-title font-mont" ref="scrollTarget">
        {{ activeAnswer.text }}
      </h3>
      <p class="asnwer-text mt-10 max-w-4xl m-auto">
        {{ activeAnswer.answer }}
      </p>
    </div>
  </div>
</template>
<script>
import { ref } from "@nuxtjs/composition-api";

export default {
  name: "Questions",
  props: {
    blok: {
      type: Object,
      required: false,
      default: {},
    },
  },
  data() {
    return {
      activeIndex: 0,
      activeIndexQuestion: 0,
      itemWidth: 0,
    };
  },
  setup() {
    const activeAnswer = ref({});

    return {
      activeAnswer,
    };
  },
  methods: {
    activate(index) {
      this.activeIndex = index;
      this.itemWidth = (100 / this.blok.questions.length) * (index + 1);
    },
    activeQuestion(index, question) {
      this.activeIndexQuestion = index;
      this.activeAnswer = question;
      const scrollTarget = this.$refs.scrollTarget;
      if (scrollTarget) {
        scrollTarget.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },
  },
  mounted() {
    if (this.blok.questions && this.blok.questions.length > 0) {
      this.activeAnswer = this.blok.questions[0].blocks[0];
    }
  },
};
</script>
<style scoped lang="scss">
.question-group {
  border-bottom: 2px solid #707070;
  @media only screen and (max-width: 768px) {
    @apply py-4;
  }
}
.answer {
  margin-top: 50px;
  text-align: center;
  border-radius: 25px;
  background-color: #efefef;
  h3 {
    color: #cd1619;
    font-size: 55px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    @apply p-6;
    h3 {
      font-size: 35px;
    }
  }
  ::v-deep p {
    @apply font-fgb;
  }
}
.answer-text {
  font-size: 20px;
  @apply font-fgb;
  @media only screen and (max-width: 768px) {
    @apply mt-6;
  }
}
.active {
  color: #cd1619;
}
.activeQuestion {
  .question {
    color: #cd1619;
  }
}
.questions-global-wrapper {
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.questions-global {
  margin-right: 50px;
  @apply font-mont;
  @media only screen and (max-width: 768px) {
    margin-right: 0;
  }
}
.scroll {
  unicode-bidi: bidi-override;
  direction: rtl;
  overflow: scroll;
  overflow-x: hidden !important;
  max-height: 350px;
  padding-left: 50px;
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    max-height: 200px;
  }
  .questions {
    direction: ltr;
  }
}
.scroll::-webkit-scrollbar {
  width: 20px; /* width of the entire scrollbar */
}

.scroll::-webkit-scrollbar-track {
  background: #cd1619; /* color of the tracking area */
  border-radius: 25px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #fff; /* color of the scroll thumb */
  border-radius: 25px; /* roundness of the scroll thumb */
  border: 2px solid #cd1619; /* creates padding around scroll thumb */
}
.question-wrapper {
  padding-left: 50px;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 768px) {
  .question {
    @apply py-2;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    @apply mt-10;
  }
}
</style>
