<template>
  <div class="container lg:flex pt-20 pb-40 lg:gap-16">
    <div class="flex-1 lg:pr-10 contact-text" v-if="isAuthenticated">
      <div class="flex">
        <div class="img-contact">
          <nuxt-img
            quality="90"
            format="webp"
            placeholder
            :src="blok.image.filename"
            class="rounded-xl"
            alt="image contact"
          />
        </div>
        <div class="ml-8 block-text">
          <h2 class="name garamond">{{ blok.name }}</h2>
          <span class="position font-bold">{{ blok.work_position }}</span>
          <div class="flex gap-x-3 mt-10">
            <div v-for="blok in blok.icons" :key="blok.id" class="icon-wrapper">
              <IconSb :blok="blok" />
            </div>
          </div>
        </div>
      </div>
      <p class="my-6 lg:mt-10 font-fgb">{{ blok.text }}</p>
    </div>
    <div class="contact-text-not-loggedin" v-else>
      <h2 v-html="$storyapi.richTextResolver.render(blok.NotLoggedInTitle)"></h2>
      <h3>{{ blok.NotLoggedInSubtitle }}</h3>
      <div class="not-loggedin-details" v-html="$storyapi.richTextResolver.render(blok.NotLoggedInDetails)"></div>
    </div>
    <div class="form flex-1 lg:pl-10">
      <div v-for="blok in blok.form" :key="blok.id">
        <ContactUsForm :blok="blok" formNameInfo="Customer Service" />
      </div>
    </div>
  </div>
</template>
<script>
import CustomInput from "~/components/Custom/CustomInput.vue";
import ContactUsForm from "~/components/storyblok/ContactUsForm.vue";
import { Icon } from "@iconify/vue2";
import { useUser } from "~/modules/customer/composables/useUser";

export default {
  name: "CustomerSupportContact",
  components: {
    CustomInput,
    ContactUsForm,
    Icon,
  },
  props: {
    blok: {
      type: Object,
      required: false,
      default: {},
    },
  },
  setup () {
    const { isAuthenticated } = useUser();

    return {
      isAuthenticated
    }
  }
};
</script>
<style scoped lang="scss">
.container {
  @media only screen and (max-width: 768px) {
    padding: 0;
    margin-bottom: 0;
  }
}
.contact-text {
  @media only screen and (max-width: 768px) {
    padding: 0 20px;
  }
}
.contact-text-not-loggedin {
  @apply lg:max-w-[50%] font-mont px-8 lg:px-0;
  h2 {
    @apply text-red-title text-3xl lg:text-5xl;
  }
  h3 {
    @apply my-8 text-2xl;
  }
  .not-loggedin-details {
    @apply lg:max-w-[70%];
    ::v-deep {
      p {
        @apply mb-4;
      }
      a {
        @apply text-red-title;
      }
    }
  }
}
.block-text {
  .name {
    max-width: 336px;
    font: normal normal normal 46px/46px Montserrat;
    letter-spacing: -0.37px;
    color: #000000;
  }
  .position {
    font: normal normal bold 23px/30px "Adobe Caslon Pro", serif;
    letter-spacing: 0px;
  }
  @media only screen and (max-width: 1200px) {
    .name {
      max-width: 260px;
      font: normal normal normal 38px/40px Montserrat;
    }
    .position {
      font: normal normal bold 18px/24px "Adobe Caslon Pro", serif;
    }
  }
  @media only screen and (max-width: 1024px) {
    .name {
      font: normal normal normal 30px/38px Montserrat;
    }
    .position {
      font: normal normal bold 16px/24px "Adobe Caslon Pro", serif;
    }
  }
  @media only screen and (max-width: 767px) {
    .name {
      max-width: 100%;
      // font: normal normal normal 46px/46px "Adobe Caslon Pro", serif;
    }
  }
  @media only screen and (max-width: 600px) {
    .name {
      max-width: 240px;
    }
    .position {
      font: normal normal bold 23px/30px "Adobe Caslon Pro", serif;
    }
  }
  @media only screen and (max-width: 480px) {
    .name {
      max-width: 260px;

      font: normal normal normal 30px/38px Montserrat;
    }
    .position {
      font: normal normal bold 16px/24px "Adobe Caslon Pro", serif;
    }
  }
}
.img-contact {
  max-width: 200px;
  img {
    height: auto;
  }
}
.textarea {
  border-radius: 20px;
  padding: 15px 0 0 15px;
  margin-top: 35px;
}
.btn {
  max-width: 192px;
  width: 100%;
  max-height: 52px;
}
.form {
  @apply font-fgb;
  .contact-us-comp {
    padding: 0;
    ::v-deep .title {
      display: none;
      @media only screen and (max-width: 768px) {
        display: block;
      }
    }
    ::v-deep .form {
      margin-top: 0;
      @media only screen and (max-width: 768px) {
        margin-top: 30px;
      }
    }
  }
}
.icon-wrapper {
  position: relative;
  border: 2px solid black;
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

@media only screen and (max-width: 1023px) {
  .container {
    @apply my-10;
  }
}
@media only screen and (max-width: 768px) {
  .container {
    @apply mt-6;
    margin-bottom: 0;
  }
}
</style>
