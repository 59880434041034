<template>
  <section class="team-comp container">
    <div class="absolute top-[-10rem]" ref="details"></div>
    <transition name="sf-fade" mode="out-in">
      <div class="more-details" v-if="moreInfo">
        <div class="left">
          <h2 class="name">{{ moreInfo.name }}</h2>
          <p class="position">{{ moreInfo.position }}</p>
          <p class="keywords">{{ moreInfo.keywords }}</p>
          <div
            class="description"
            v-html="$storyapi.richTextResolver.render(moreInfo.description)"
          ></div>
          <div class="social-media">
            <div
              class="social"
              v-for="(item, index) in moreInfo.SocialLinks"
              :key="index + 'social'"
            >
              <a :href="item | parseSocialLink" target="_blank" aria-label="Wish icon">
                <Icon class="wishIcon" :icon="item.icon" />
              </a>
            </div>
          </div>
        </div>
        <div class="right">
          <nuxt-img
            quality="90"
            format="webp"
            placeholder
            :src="moreInfo.photo.filename + '/m/filters:format(webp)'"
            :alt="moreInfo.name"
            class="rounded-xl"
          />
        </div>
      </div>
    </transition>
    <div class="team-members">
      <div
        class="member"
        v-for="(item, index) in blok.List"
        :key="index + 'member'"
        @click="setMoreInfo(item)"
      >
        <div class="image-wrapper rounded-xl">
          <nuxt-img
            quality="90"
            format="webp"
            placeholder
            :src="item.photo.filename + '/m/filters:format(webp)'"
            :alt="item.name"
            class="rounded-xl"
          />
          <div class="overlay rounded-xl" v-if="moreInfo === item"></div>
        </div>
        <h2 class="name mont">{{ item.name }}</h2>
        <p class="position">{{ item.position }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { Icon } from "@iconify/vue2";
import { ref } from "@nuxtjs/composition-api";

export default {
  name: "TeamComp",
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
  components: {
    Icon,
  },
  setup() {
    const moreInfo = ref(null);
    const details = ref(null);

    const setMoreInfo = (p) => {
      if (moreInfo.value && p === moreInfo.value) {
        moreInfo.value = null;
      } else {
        moreInfo.value = p;
      }

      setTimeout(() => {
        details.value?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);
    };
    return {
      moreInfo,
      setMoreInfo,
      details,
    };
  },
  filters: {
    parseSocialLink(value) {
      const icon = value.icon;
      let link = "";
      if (icon.includes("whatsapp")) {
        link = `https://wa.me/${value.phone}`;
      } else if (icon.includes("email")) {
        link = `mailto:${value.email}`;
      } else if (value.phone && value.phone.length > 0) {
        link = `tel:${value.phone}`;
      } else {
        link = value.link;
      }

      return link;
    },
  },
};
</script>

<style lang="scss" scoped>
.team-comp {
  @apply relative;
  padding-top: 80px;
  padding-bottom: 80px;
  .more-details {
    @apply flex justify-around;
    max-width: 80%;
    margin: 0 auto;
    margin-bottom: 84px;
    background: #efefef 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    border-radius: 20px;
    .left {
      max-width: 50%;
      padding: 43px 140px;
      width: 765px;
      max-width: 100%;
      .name {
        text-align: left;
        font: normal normal bold 50px/40px Montserrat;
        letter-spacing: 0px;
        margin-bottom: 45px;
      }
      .position {
        text-align: left;
        font: normal normal normal 35px/42px "Adobe Caslon Pro", serif;
        letter-spacing: 0px;
      }
      .keywords {
        text-align: left;
        font: normal normal normal 20px/30px "Adobe Caslon Pro", serif;
        letter-spacing: 0px;
      }
      .description {
        margin-top: 40px;
        @apply font-fgb;
      }
      .social-media {
        @apply flex mt-8 text-5xl gap-x-2;
      }
    }
    .right {
      @apply flex justify-end;
      img {
        margin-top: auto;
        width: auto;
        height: auto;
        object-fit: cover;
      }
    }
  }
  .team-members {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 50px;
    .member {
      @apply cursor-pointer;
      .image-wrapper {
        position: relative;
        max-width: 290px;
        background: #efefef 0% 0% no-repeat padding-box;
        mix-blend-mode: multiply;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .overlay {
          position: absolute;
          display: block;
          height: 100%;
          width: 100%;
          background: #cd16192c;
          top: 0;
        }
      }
      .name {
        margin-top: 30px;
        text-align: center;
        font: normal normal bold 25px/40px Montserrat;
        letter-spacing: 0px;
      }
      .position {
        margin: 15px 0 30px 0;
        text-align: center;
        font: normal normal normal 20px/24px "Franklin Gothic Book", sans-serif;
        letter-spacing: 0px;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    @apply py-16;
    .team-members {
      @apply gap-4;
      grid-template-columns: repeat(4, 1fr);
    }
    .more-details {
      .left {
        padding: 40px 30px;
      }
      .right {
        img {
          object-fit: cover;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .team-members {
      grid-template-columns: repeat(3, 1fr);
    }
    .more-details {
      @apply flex-col;
      max-width: 100%;
      .left {
        padding: 40px 30px;
      }
      .right {
        img {
          object-fit: cover;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .team-members {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: 480px) {
    .team-members {
      @apply flex flex-wrap justify-around;
    }
  }
}
</style>
