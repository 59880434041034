<template>
  <section class="custom-support-hero">
    <div
      class="text max-w-5xl"
      v-html="$storyapi.richTextResolver.render(blok.text)"
    ></div>
  </section>
</template>

<script>
export default {
  name: "CustomerSupportHero",
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-support-hero {
  @apply flex justify-center items-center font-mont;
  width: 100%;
  height: calc(80vh - 243px);
  background: #3c3c3c;
  background-position: center !important;
  ::v-deep .text {
    text-align: center;
    font: normal normal normal 55px/80px Montserrat;
    color: white;
    b {
      font: normal normal bold 95px/100px Montserrat;
    }
    p:last-child {
      font: normal normal normal 45px/100px Montserrat;
    }
  }
  @media only screen and (max-width: 1024px) {
    ::v-deep .text {
      text-align: center;
      font: normal normal normal 45px/55px Montserrat;
      color: white;
      b {
        font: normal normal bold 70px/75px Montserrat;
      }
      p:last-child {
        font: normal normal normal 35px/55px Montserrat;
      }
    }
  }
  @media only screen and (max-width: 1023px) {
    margin-bottom: 2rem;
  }
  @media only screen and (max-width: 768px) {
    height: 50vh;
    margin-bottom: 0;
    ::v-deep .text {
      text-align: center;
      font: normal normal normal 44px/55px Montserrat;
      color: white;
      b {
        font: normal normal bold 55px/55px Montserrat;
      }
      p:last-child {
        font: normal normal normal 35px/55px Montserrat;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    height: calc(100vh - 60vh);
    ::v-deep .text {
      @apply px-2;
      text-align: center;
      font: normal normal normal 33px/44px Montserrat;
      color: white;
      b {
        font: normal normal bold 33px/44px Montserrat;
      }
      p:last-child {
        font: normal normal normal 28px/44px Montserrat;
      }
    }
  }
}
</style>
